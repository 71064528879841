.mobile-page {
  background-color: #044e47;
  height: 100vh;
}
#demo {
  color: white;
  margin: 5px;
  padding: 5px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}
