.payment_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    
   
}
.payment_button{
   

    &:hover{
        opacity: 0.5;
    }
}
