.collaboration-page-background {
  background: url(../../images/collab-title.jpg) no-repeat center top / contain !important;
}
.donate-page-background {
  background: url(../../images/donate-title.webp) no-repeat center top / contain !important;
}
.volunteer-page-background {
  background: url(../../images/volunteer-title.webp) no-repeat center top /
    contain !important;
  // 	background-position: center;
  // background-size: contain;
}
.about-page-background {
  background: url(../../images/about-title.webp) no-repeat center top / contain !important;
}
.leopard {
  background: url(../../images/leopard-title.webp) no-repeat center top /
    contain !important;
}
.tiger {
  background: url(../../images/tree-title.webp) no-repeat center top / contain !important;
}
.water {
  background: url(../../images/water-title.webp) no-repeat center top / contain !important;
}
.wildlife {
  background: url(../../images/wildlife-title.png) no-repeat center top /
    contain !important;
}
.forest {
  background: url(../../images/forest-title.png) no-repeat center top / contain !important;
}
.technology {
  background: url(../../images/technology-title.png) no-repeat center top /
    contain !important;
}
.partnerpage {
  padding: 40px !important;
}
.partnerimage {
  display: flex;
  // justify-items: center;
  justify-content: space-evenly;
  margin: 10px;
  padding: 10px;
}

.wpo-breadcumb-area {
  background: url(../../images/page-title.webp) no-repeat center top / contain;
  min-height: 300px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1;

  @media (max-width: 767px) {
    min-height: 100px;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    content: "";
    z-index: -1;
  }
  .wpo-breadcumb-wrap {
    text-align: center;

    h2 {
      font-size: 60px;
      color: #fff;
      line-height: 70px;
      margin-top: -10px;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 10px;
      }
    }

    ul {
      li {
        display: inline-block;
        padding: 0px 5px;
        color: #fff;
        position: relative;
        font-size: 20px;
        font-family: $heading-font;

        @media (max-width: 767px) {
          font-size: 18px;
        }

        &:after {
          content: "/";
          position: relative;
          left: 7px;
        }

        &:last-child {
          span {
            color: #cbd4fd;
          }
          &:after {
            display: none;
          }
        }

        a {
          color: #fff;
          font-size: 20px;
          transition: all 0.3s;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }
    }
  }
}

.wpo-about-section.wpo-about-section-s2 {
  padding-top: 170px;
}
.page-title-heading {
  font-size: 60px;
  color: white;
  // z-index: -1;
  position: relative;
  margin-top: -80px;
  @media (max-width: 767px) {
    font-size: 30px;
    margin-top: -40px;
  }
}
