/*---------------------------
	Fonts
----------------------------*/

  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&display=swap');





@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/text-font/Roboto-Regular-webfont.woff') format("truetype");
    font-weight: 600;
    font-style: normal;
  }

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Regular'), url('../../fonts/text-font/Roboto-Regular-webfont.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    src: local('Roboto SemiBold'), url('../../fonts/text-font/Roboto-Regular-webfont.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), url('../../fonts/text-font/Roboto-Bold.woff') format('woff');
    }

// fonts
$base-font-size: 15;
$base-font: 'Roboto';
$heading-font: 'Roboto';



// color
$dark-gray: #212158;
$body-color: #4f555a;
$white: #fff;
$light: #8188a9;
$black: #000;
$cyan: #848892;

$theme-primary-color: #D09800;
$theme-primary-color-s2: #5dc4b8;
$body-bg-color: #fff;
$section-bg-color: #fef6f3;
$section-bg-color2: #e9fafa;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #e1e1e1;
$border-color-s2: #d8e0f1;
